import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/en-gb'

dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(advancedFormat)
dayjs.locale('en-gb')

export const useDayjs = () => dayjs
