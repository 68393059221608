<script setup>
import { computed, ref } from 'vue'
import { useBusinessBenchmarkingStore } from '@/stores/businessBenchmarkingStore.js'
import { useBusinessReportService } from '@/services/businessReportService.js'
import { reportType } from '@/enums/reportType.js'
import { useRoute } from 'vue-router'

const props = defineProps({
  minimalData: {
    type: Boolean,
    default: false
  }
})

const businessBenchmarkingStore = useBusinessBenchmarkingStore()
const businessReportService = useBusinessReportService()
const route = useRoute()

const activeType = ref('Amount')
const options = ref(['Amount', 'Change'])

const loading = computed(() => businessBenchmarkingStore.totalSalesAndTransactionsLoading)
const meta = computed(() => businessBenchmarkingStore.totalSalesAndTransactionsData?.meta)
const labels = computed(() => {
  return Object.values(meta.value?.labels.currentYear ?? {})
})
const periods = computed(() => businessBenchmarkingStore.totalSalesAndTransactionsData?.periods)
const salesData = computed(() => {
  return {
    currentYear: Object.values(periods.value?.currentYear ?? {}).map((period) => period.sales),
    oneYearPrior: Object.values(periods.value?.oneYearPrior ?? {}).map((period) => period.sales),
    twoYearsPrior: Object.values(periods.value?.twoYearsPrior ?? {}).map((period) => period.sales)
  }
})
const salesChangeData = computed(() => {
  return {
    currentYear: Object.values(periods.value?.currentYear ?? {}).map(
      (period) => period.salesChange
    ),
    oneYearPrior: Object.values(periods.value?.oneYearPrior ?? {}).map(
      (period) => period.salesChange
    ),
    twoYearsPrior: Object.values(periods.value?.twoYearsPrior ?? {}).map(
      (period) => period.salesChange
    )
  }
})
const salesChartData = computed(() => {
  return {
    datasets: businessReportService.prepareWidgetChartDatasets(
      salesData.value.currentYear,
      salesData.value.oneYearPrior,
      salesData.value.twoYearsPrior,
      false
    ),
    labels: labels.value
  }
})
const salesChangeChartData = computed(() => {
  return {
    datasets: businessReportService.prepareWidgetChartDatasets(
      salesChangeData.value.currentYear,
      salesChangeData.value.oneYearPrior,
      salesChangeData.value.twoYearsPrior,
      false
    ),
    labels: labels.value
  }
})

const chartOptions = computed(() => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    spanGaps: true,
    plugins: {
      legend: {
        display: true
      }
    },
    scales: {
      x: {
        display: true
      },
      y: {
        display: true
      }
    }
  }
})

const tableData = computed(() => {
  const currentYearSales = salesData.value?.currentYear
  const oneYearPriorSales = salesData.value?.oneYearPrior
  const twoYearsPriorSales = salesData.value?.twoYearsPrior

  return labels.value?.map((label, index) => {
    const currentYear = currentYearSales[index] ?? 0
    const oneYearPrior = oneYearPriorSales[index] ?? 0
    const twoYearsPrior = twoYearsPriorSales[index] ?? 0

    return {
      label: label,
      currentYear: {
        value: currentYear
          ? currentYear?.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP'
            })
          : null,
        difference: businessReportService.calculateDifferencePercentage(currentYear, oneYearPrior)
      },
      oneYearPrior: {
        value: oneYearPrior
          ? oneYearPrior?.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP'
            })
          : null,
        difference: businessReportService.calculateDifferencePercentage(oneYearPrior, twoYearsPrior)
      },
      twoYearsPrior: {
        value: twoYearsPrior
          ? twoYearsPrior?.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP'
            })
          : null
      }
    }
  })
})
const monthField = computed(() => {
  if (route.meta.reportTemplateType === reportType.RETAIL_SALES_WEEKLY) {
    return 'Calendar Week'
  }
  return 'Month'
})
</script>

<template>
  <Card class="border">
    <template #content>
      <div class="w-full flex">
        <div>
          <div class="font-bold text-lg">Sales change</div>
          <div>Absolute sales numbers for the last 12 months</div>
        </div>
        <div class="ml-auto">
          <SelectButton v-model="activeType" :options="options" :allow-empty="false" />
        </div>
      </div>
      <div v-if="loading">
        <Skeleton height="20rem" class="mt-6" />
        <Skeleton height="30rem" class="mt-4" />
      </div>
      <div v-else>
        <div v-if="activeType === 'Amount'" class="w-full mt-6">
          <Chart type="line" :options="chartOptions" :data="salesChartData" :height="300" />
        </div>
        <div v-else>
          <Chart type="line" :options="chartOptions" :data="salesChangeChartData" :height="300" />
        </div>

        <div v-if="!minimalData" class="mt-6">
          <DataTable :value="tableData" class="border rounded" show-gridlines>
            <Column field="label" :header="monthField"></Column>
            <Column :header="meta?.currentYear">
              <template #body="slotProps">
                <div v-if="slotProps.data?.currentYear.value">
                  {{ slotProps.data?.currentYear.value }}
                  <span
                    :class="
                      slotProps.data.currentYear.difference > 0
                        ? 'text-primary-300'
                        : 'text-red-400'
                    "
                  >
                    {{ slotProps.data.currentYear.difference }}%
                  </span>
                </div>
                <div v-else>--</div>
              </template>
            </Column>
            <Column :header="meta.oneYearPrior">
              <template #body="slotProps">
                <div v-if="slotProps.data.oneYearPrior.value">
                  {{ slotProps.data.oneYearPrior.value }}
                  <span
                    :class="
                      slotProps.data.oneYearPrior.difference >= 0
                        ? 'text-primary-300'
                        : 'text-red-400'
                    "
                  >
                    {{ slotProps.data.oneYearPrior.difference }}%
                  </span>
                </div>
                <div v-else>--</div>
              </template>
            </Column>
            <Column :header="meta.twoYearsPrior">
              <template #body="slotProps">
                <div v-if="slotProps.data.twoYearsPrior.value">
                  {{ slotProps.data.twoYearsPrior.value }}
                </div>
                <div v-else>--</div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </template>
  </Card>
</template>

<style scoped></style>
