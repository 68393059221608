<script setup>
import { computed } from 'vue'
import GenericChartWidget from '@/components/reports/widgets/GenericChartWidget.vue'
import { useBusinessReportService } from '@/services/businessReportService.js'
import { useBusinessBenchmarkingStore } from '@/stores/businessBenchmarkingStore.js'
import { widgetDataFormatType } from '@/enums/widgetDataFormatType.js'

const businessBenchmarkingStore = useBusinessBenchmarkingStore()
const businessReportService = useBusinessReportService()

const loading = computed(() => businessBenchmarkingStore.totalSalesAndTransactionsLoading)
const labels = computed(() => {
  return Object.values(businessBenchmarkingStore.totalSalesAndTransactionsData?.meta?.labels ?? {})
    .map((period) => {
      return Object.values(period)
    })
    .reduce((acc, cur) => {
      return acc.concat(cur)
    }, [])
})
const totals = computed(() => businessBenchmarkingStore.totalSalesAndTransactionsData?.totals)
const periods = computed(() => businessBenchmarkingStore.totalSalesAndTransactionsData?.periods)
const salesData = computed(() => {
  return {
    currentYear: Object.values(periods.value?.currentYear ?? {}).map((period) => period.sales),
    oneYearPrior: Object.values(periods.value?.oneYearPrior ?? {}).map((period) => period.sales),
    twoYearsPrior: Object.values(periods.value?.twoYearsPrior ?? {}).map((period) => period.sales)
  }
})
const chartData = computed(() => {
  return {
    datasets: businessReportService.prepareWidgetChartDatasets(
      salesData.value.currentYear,
      salesData.value.oneYearPrior,
      salesData.value.twoYearsPrior,
      true
    ),
    labels: labels.value
  }
})

const mappedTotals = computed(() => {
  return {
    currentYear: {
      value:
        businessReportService.formatTotal(
          businessReportService.shortenNumber(totals.value?.sales.currentYear),
          widgetDataFormatType.MONEY
        ) ?? 0
    },
    oneYearPrior: {
      value: businessReportService.formatTotal(
        businessReportService.shortenNumber(totals.value?.sales.oneYearPrior),
        widgetDataFormatType.MONEY
      ),
      difference: businessReportService.calculateDifferencePercentage(
        totals.value?.sales.currentYear,
        totals.value?.sales.oneYearPrior
      )
    },
    twoYearsPrior: {
      value: businessReportService.formatTotal(
        businessReportService.shortenNumber(totals.value?.sales.twoYearsPrior),
        widgetDataFormatType.MONEY
      ),
      difference: businessReportService.calculateDifferencePercentage(
        totals.value?.sales.currentYear,
        totals.value?.sales.twoYearsPrior
      )
    }
  }
})
</script>

<template>
  <GenericChartWidget
    title="Total sales amount"
    :loading="loading"
    :totals="mappedTotals"
    :colors="businessReportService.getColors()"
    :chart-data="chartData"
    :data-format="widgetDataFormatType.MONEY"
  />
</template>

<style scoped></style>
