<script setup>
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useAuthStore } from '@/stores/authStore.js'

const route = useRoute()
const userStore = useUserStore()
const authStore = useAuthStore()
// console.log(route.meta);
const icon = computed(() => route.meta.icon)
const title = computed(() => route.meta.title)
const hideHeader = computed(() => route.meta.hideHeader)
const userName = computed(() => `${userStore.user?.first_name} ${userStore.user?.last_name}`)

const menu = ref()
const items = ref([
  {
    label: 'Logout',
    command: () => {
      authStore.logout(null)
    }
  }
])

const toggle = (event) => {
  menu.value.toggle(event)
}
const isFaIcon = computed(() => typeof icon.value === 'object')
</script>

<template>
  <div v-if="!hideHeader" class="header border-b leading-6 flex">
    <div class="py-4 px-6 flex items-center">
      <font-awesome-icon v-if="isFaIcon" :icon="icon" />
      <img v-else :src="`/assets/icons/${icon}`" alt="" />
      <div class="pl-5 text-lg">{{ title }}</div>
    </div>

    <div class="ml-auto flex text-nowrap">
      <!--      <div class="pr-6">
        <img src="/assets/icons/notifications.svg" alt="notifications" />
      </div>-->

      <div class="border-l flex">
        <Button
          type="button"
          severity="secondary"
          size="small"
          class="px-16 flex justify-around bg-white"
          @click="toggle"
        >
          {{ userName }}
          <font-awesome-icon :icon="faChevronDown" />
        </Button>
        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
