import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/userStore.js'
import { ref } from 'vue'
import { useAccountStore } from '@/stores/accountStore.js'
import { useOidc } from '@/composables/oidc.js'
import { useAxios } from '@/composables/axios.js'

export const useAuthStore = defineStore('auth', () => {
  const authenticated = ref(null)
  const loading = ref(false)
  const profile = ref(null)
  const token = ref(null)

  function $reset() {
    authenticated.value = null
    loading.value = false
    profile.value = null
    token.value = null
  }

  function setAuthenticated(authStatus) {
    console.log('[AuthStore] setting authentication status to ', authStatus)
    authenticated.value = authStatus
  }

  function setProfile(newProfile) {
    profile.value = newProfile
  }

  function setToken(newToken) {
    const axios = useAxios()
    axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${newToken}`
      return config
    })

    token.value = newToken
  }

  async function login() {
    resetStores()

    console.log('[AuthStore] logging in user')
    const oidc = await useOidc()
    console.log(oidc)
    await oidc.login({
      doesCurrentHrefRequiresAuth: false,
      redirectUrl: `${window.location.origin}/`
    })
  }

  async function logout(redirectUrl) {
    console.info('[AuthStore] logging out user')

    resetStores()

    const redirectEndpoint = redirectUrl ?? '/login'
    const oidc = await useOidc()

    await oidc.logout({
      redirectTo: 'home'
    })
  }

  function resetStores() {
    const userStore = useUserStore()
    userStore.$reset()
    const accountStore = useAccountStore()
    accountStore.$reset()
    $reset()
    loading.value = true
  }

  return {
    $reset,
    authenticated,
    loading,
    profile,
    token,
    setAuthenticated,
    setProfile,
    setToken,
    login,
    logout
  }
})
