<script setup>
import { RouterView } from 'vue-router'
import { useApplicationStore } from '@/stores/applicationStore.js'

const applicationStore = useApplicationStore()
</script>

<template>
  <div
    v-if="!applicationStore.initialized"
    class="flex justify-center flex-wrap w-full h-full pt-12 items-center"
  >
    <ProgressSpinner />
  </div>
  <RouterView v-else />
  <Toast />
</template>
