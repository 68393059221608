import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useApplicationStore = defineStore('app', () => {
  const initialized = ref(false)

  function $reset() {
    initialized.value = false
  }

  return {
    $reset,
    initialized
  }
})
