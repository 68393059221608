import { definePreset, palette } from '@primevue/themes'

import Aura from '@primevue/themes/aura'

const bgDark = palette('#182230')
const red = palette('#f04438')
const orange = palette('#f79009')
const green = palette('#206142')

const htaPrimevuePreset = definePreset(Aura, {
  primitive: {
    green: {
      50: '#d9f2e6',
      100: '#b2e6cd',
      200: '#94d1b4',
      300: '#72c09b',
      400: '#2b8259',
      500: '#206142',
      600: '#1c5439',
      700: green[700],
      800: green[800],
      900: green[900],
      950: green[950]
    },
    red: {
      50: red[50],
      100: red[100],
      200: red[200],
      300: red[300],
      400: red[400],
      500: red[500],
      600: red[600],
      700: red[700],
      800: red[800],
      900: red[900],
      950: red[950]
    },
    orange: {
      50: orange[50],
      100: orange[100],
      200: orange[200],
      300: orange[300],
      400: orange[400],
      500: orange[500],
      600: orange[600],
      700: orange[700],
      800: orange[800],
      900: orange[900],
      950: orange[950]
    },
    slate: {
      50: '#f8fafc',
      100: '#f2f4f7',
      200: '#e2e8f0',
      300: '#d0d5dd',
      400: '#98a2b3',
      500: '#667085',
      600: '#475467',
      700: '#334155',
      800: '#182230',
      900: '#0f172a',
      950: '#020617'
    }
  },
  semantic: {
    primary: {
      50: '{green.50}',
      100: '{green.100}',
      200: '{green.200}',
      300: '{green.300}',
      400: '{green.400}',
      500: '{green.500}',
      600: '{green.600}',
      700: '{green.700}',
      800: '{green.800}',
      900: '{green.900}',
      950: '{green.950}'
    },
    colorScheme: {
      light: {
        surface: {
          0: '#ffffff',
          50: '#fcfcfd',
          100: '#f9fafb',
          200: '#f2f4f7',
          300: '{slate.300}',
          400: '{slate.400}',
          500: '{slate.500}',
          600: '{slate.600}',
          700: '{slate.700}',
          800: '{slate.800}',
          900: '{slate.900}',
          950: '{slate.950}'
        },
        text: {
          color: '{surface.800}',
          hoverColor: '{surface.800}',
          mutedColor: '{surface.500}',
          hoverMutedColor: '{surface.600}'
        },
        content: {
          background: '{surface.0}',
          hoverBackground: '{surface.100}',
          borderColor: '{surface.200}',
          color: '{surface.600}',
          hoverColor: '{text.hover.color}'
        },
        primary: {
          color: '{primary.500}',
          contrastColor: '#ffffff',
          hoverColor: '{primary.400}',
          activeColor: '{primary.700}'
        }
      },
      dark: {
        surface: {
          0: '#ffffff',
          50: '#fcfcfd',
          100: '#f9fafb',
          200: '#f2f4f7',
          300: '{slate.300}',
          400: '{slate.400}',
          500: '{slate.500}',
          600: '{slate.600}',
          700: '{slate.700}',
          800: '{slate.800}',
          900: '{slate.900}',
          950: '{slate.950}'
        },
        text: {
          color: '{surface.0}',
          hoverColor: '{surface.0}',
          mutedColor: '{surface.400}',
          hoverMutedColor: '{surface.300}'
        },
        content: {
          background: '{surface.800}',
          hoverBackground: '{surface.700}',
          borderColor: '{surface.700}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}'
        },
        primary: {
          color: '{primary.500}',
          contrastColor: '#ffffff',
          hoverColor: '{primary.400}',
          activeColor: '{primary.700}'
        }
      }
    }
  }
})

export default htaPrimevuePreset
