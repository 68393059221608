<script setup>
import GenericChartWidget from '@/components/reports/widgets/GenericChartWidget.vue'
import { useBusinessReportService } from '@/services/businessReportService.js'
import SalesChangeWidget from '@/components/reports/widgets/SalesChangeWidget.vue'
import TransactionsWidget from '@/components/reports/widgets/TransactionsWidget.vue'
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { useReportStore } from '@/stores/reportStore.js'
import { reportType } from '@/enums/reportType.js'
import { storeToRefs } from 'pinia'
import BusinessTotalSalesWidget from '@/components/reports/widgets/business/BusinessTotalSalesWidget.vue'
import { useBusinessBenchmarkingStore } from '@/stores/businessBenchmarkingStore.js'
import BusinessSalesChangeWidget from '@/components/reports/widgets/business/BusinessSalesChangeWidget.vue'
import BusinessTotalTransactionsWidget from '@/components/reports/widgets/business/BusinessTotalTransactionsWidget.vue'
import BusinessSalesChange from '@/components/reports/widgets/business/BusinessSalesChange.vue'
import BusinessTransactions from '@/components/reports/widgets/business/BusinessTransactions.vue'
import { useAccountStore } from '@/stores/accountStore.js'

const { minimalData } = defineProps({
  minimalData: {
    type: Boolean,
    default: false
  }
})
const widgetsData = ref({})
const businessReportService = useBusinessReportService()
const route = useRoute()
const reportStore = useReportStore()
const accountStore = useAccountStore()
const businessBenchmarkingStore = useBusinessBenchmarkingStore()
const { dates, forceReload } = storeToRefs(reportStore)

const reportTemplateType = computed(() => {
  if (!route.meta.reportTemplateType) {
    if (accountStore.permissions.reports.RETAIL_SALES_MONTHLY) {
      return reportType.RETAIL_SALES_MONTHLY
    }
    if (accountStore.permissions.reports.RETAIL_SALES_WEEKLY) {
      return reportType.RETAIL_SALES_WEEKLY
    }
    if (accountStore.permissions.reports.GROWER_SALES) {
      return reportType.GROWER_SALES
    }
  }
  return route.meta.reportTemplateType
})

const date = computed(() => {
  return businessBenchmarkingStore.totalSalesAndTransactionsData?.meta?.currentYear ?? ''
})
function getReportWidgetsData() {
  reportStore.loading = true
  businessReportService.getWidgetsData(reportTemplateType.value, minimalData).then((data) => {
    widgetsData.value = data
    reportStore.loading = false
  })
}
onBeforeMount(() => {
  getReportWidgetsData()
})

watch(dates, () => {
  getReportWidgetsData()
})
watch(route, () => {
  getReportWidgetsData()
})
watch(forceReload, () => {
  if (forceReload) {
    getReportWidgetsData()
    forceReload.value = false
  }
})
</script>

<template>
  <div>
    <div v-if="!minimalData" class="w-full justify-center flex py-6 bg-surface-50 border-b">
      <div class="w-10/12">
        <div class="text-3xl font-semibold inline-block">Business Report</div>
        <div class="inline-block ml-2">
          {{ date }}
        </div>
      </div>
    </div>
    <div class="w-full justify-center flex mt-6">
      <div class="grid gap-4" :class="minimalData ? 'w-full grid-cols-2' : 'w-10/12 grid-cols-3'">
        <div class="w-full text-2xl" :class="minimalData ? 'col-span-2' : 'col-span-3'">Sales</div>
        <BusinessTotalSalesWidget />
        <BusinessSalesChangeWidget />
        <BusinessTotalTransactionsWidget v-if="!minimalData" />

        <div :class="minimalData ? 'col-span-2' : 'col-span-3'">
          <BusinessSalesChange />
        </div>
        <div :class="minimalData ? 'col-span-2' : 'col-span-3'">
          <BusinessTransactions v-if="!minimalData" />
        </div>

        <!--        <GenericChartWidget
          v-for="(widgetData, key) in widgetsData.smallWidgets"
          :key="key"
          :title="widgetData.title"
          :data-format="widgetData.type"
          :chart-data="widgetData.chartData"
          :totals="widgetData.totals"
          :colors="widgetData.colors"
          :minimal-data="minimalData"
        />-->
        <!--        <SalesChangeWidget
          :chart-amount-data="widgetsData.bigWidgets?.amount ?? null"
          :chart-change-data="widgetsData.bigWidgets?.change ?? null"
          :colors="widgetsData.bigWidgets?.colors ?? null"
          :minimal-data="minimalData"
        />-->
        <!--        <TransactionsWidget
          v-if="!minimalData"
          :colors="widgetsData.bigWidgets?.colors ?? null"
          :chart-transactions-data="widgetsData.bigWidgets?.transaction ?? null"
        />-->
      </div>
    </div>
  </div>
</template>

<style scoped></style>
