import { useUserStore } from '@/stores/userStore.js'
import { permissionType, permissionTypeIsEqualOrBetterThan } from '@/enums/permissionType.js'
import { useAccountStore } from '@/stores/accountStore.js'
import { reportType } from '@/enums/reportType.js'

class PermissionService {
  getPermission(currentReportType) {
    const userStore = useUserStore()
    const accountStore = useAccountStore()
    const currentAccount = accountStore.currentAccount
    const currentAccountId = currentAccount.id
    const currentAccountOwnerId = currentAccount.owner_id

    if (currentReportType === 'ACCOUNT') {
      if (userStore.user.id === currentAccountOwnerId) {
        return permissionType.OWNER
      }
      if (userStore.permissions[currentAccountId].ACCOUNT === permissionType.ADMIN) {
        return permissionType.ADMIN
      }
    }
    if (!accountStore.permissions.reports[currentReportType]) {
      return permissionType.NONE
    }

    if (!userStore.user) {
      return permissionType.NONE
    }

    if (userStore.user.id === currentAccountOwnerId) {
      return permissionType.OWNER
    }

    if (userStore.permissions[currentAccountId].ACCOUNT === permissionType.ADMIN) {
      return permissionType.ADMIN
    }

    return userStore.permissions[currentAccountId][currentReportType]
  }

  userCan(reportType, minimumPermission) {
    const actualPermission = this.getPermission(reportType)
    console.log('[PermissionService] permission type', actualPermission)
    if (!minimumPermission) {
      minimumPermission = permissionType.VIEW
    }
    console.log('[PermissionService] minimum permission', minimumPermission)
    console.log(
      '[PermissionService] user can',
      permissionTypeIsEqualOrBetterThan(minimumPermission, actualPermission)
    )
    return permissionTypeIsEqualOrBetterThan(minimumPermission, actualPermission)
  }
}

export const usePermissionService = () => new PermissionService()
