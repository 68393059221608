<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { useUserStore } from '@/stores/userStore'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { faBuildingWheat, faSeedling, faUsers } from '@fortawesome/free-solid-svg-icons'
import { usePermissionService } from '@/services/permissionService.js'
import { reportType } from '@/enums/reportType.js'
import { useAccountStore } from '@/stores/accountStore.js'

const accountStore = useAccountStore()
const router = useRouter()
const permissionService = usePermissionService()

const accountName = computed(() => accountStore.currentAccount.name)

const menuItems = ref([
  {
    label: 'Home',
    icon: faBuilding
  }
])

const accounts = computed(() => {
  const accounts = [
    {
      value: accountStore.account.id,
      label: accountStore.account.name,
      icon: faBuildingWheat
    }
  ]
  accountStore.account.premises.forEach((item) => {
    accounts.push({
      value: item.id,
      label: item.name,
      icon: faSeedling
    })
  })
  return accounts
})

const currentAccount = ref(
  accounts.value.find((account) => account.value === accountStore.currentAccount.id)
)

async function changeCurrentAccount(accountId) {
  await accountStore.changeCurrentAccount(accountId)

  await router.replace('/')
}
</script>

<template>
  <div class="w-full flex flex-wrap p-6">
    <div class="w-full">
      <img src="/assets/images/logo.png" alt="logo" class="w-3/12" />
    </div>
    <div class="w-full py-6 text-muted-color border-b">
      <Select
        v-if="accountStore.permissions.is_benchmark_premsises"
        v-model="currentAccount"
        :options="accounts"
        option-label="label"
        fluid
        filter
        @change="changeCurrentAccount(currentAccount.value)"
      >
        <template #value="slotProps">
          <div class="flex items-center">
            <font-awesome-icon :icon="slotProps.value.icon" />
            <div class="ml-2">{{ slotProps.value.label }}</div>
          </div>
        </template>
        <template #option="slotProps">
          <div class="flex items-center">
            <font-awesome-icon :icon="slotProps.option.icon" />
            <div class="ml-2">{{ slotProps.option.label }}</div>
          </div>
        </template>
      </Select>
      <div v-else class="flex items-center p-3 rounded">
        <font-awesome-icon :icon="faBuildingWheat" />
        <div class="ml-2">{{ accountStore.currentAccount.name }}</div>
      </div>
    </div>
    <div class="w-full flex flex-wrap py-6">
      <Button as="router-link" to="/" severity="secondary" class="w-full text-left justify-start">
        <img src="/assets/icons/home.svg" alt="" />
        <span>Home</span>
      </Button>
    </div>
    <div class="w-full flex subtitle">
      <div class="text-muted-color text-xs z-50 bg-white pr-2">Benchmarking</div>
    </div>
    <div
      v-if="permissionService.userCan(reportType.RETAIL_SALES_MONTHLY)"
      class="w-full flex flex-wrap pt-6"
    >
      <Button
        as="router-link"
        to="/sales/retail/monthly/report"
        severity="secondary"
        class="w-full text-left justify-start"
      >
        <img src="/assets/icons/sales.svg" alt="" />
        <span>Retail Sales</span>
      </Button>
    </div>
    <div
      v-if="permissionService.userCan(reportType.RETAIL_SALES_WEEKLY)"
      class="w-full flex flex-wrap pt-2"
    >
      <Button
        as="router-link"
        to="/sales/retail/weekly/report"
        severity="secondary"
        class="w-full text-left justify-start"
      >
        <img src="/assets/icons/sales.svg" alt="" />
        <span>RDF Weekly Retail Sales</span>
      </Button>
    </div>
    <div
      v-if="permissionService.userCan(reportType.GROWER_SALES)"
      class="w-full flex flex-wrap pt-2"
    >
      <Button
        as="router-link"
        :to="{ name: 'sales.grower.monthly.report' }"
        severity="secondary"
        class="w-full text-left justify-start"
        disabled
      >
        <img src="/assets/icons/sales.svg" alt="" />
        <span>Grower Sales</span>
      </Button>
    </div>
    <div class="w-full flex subtitle my-4">
      <div class="text-muted-color text-xs z-50 bg-white pr-2">Account</div>
    </div>
    <div v-if="permissionService.userCan('ACCOUNT')" class="w-full flex flex-wrap pt-2">
      <Button
        as="router-link"
        :to="{ name: 'account.organisation' }"
        severity="secondary"
        class="w-full text-left justify-start"
        disabled
      >
        <font-awesome-icon :icon="faUsers" />
        <span>Organisation</span>
      </Button>
    </div>
  </div>
</template>

<style scoped>
.subtitle {
  position: relative;

  &::after {
    content: ''; /* this is necessary for the pseudo-element to work */
    display: block;
    height: 2px; /* height of the bar */
    background-color: #f8fafc; /* color of the bar */
    position: absolute;
    /* start from the end of the text */
    top: 50%; /* align with the middle of the text */
    width: 100%; /* width of the bar */
  }
}
</style>
