<script setup>
import { computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  chartData: {
    type: Object,
    required: true
  },
  colors: {
    type: Object,
    required: true
  },
  totals: {
    type: Object,
    required: true
  },
  minimalData: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const chartOptions = computed(() => {
  const textColor = props.colors.color1
  const textColorSecondary = props.colors.color2
  const surfaceBorder = props.colors.color3
  return {
    maintainAspectRatio: false,
    responsive: true,
    spanGaps: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: textColor
        }
      }
    },
    elements: {
      point: {
        //borderWidth: 1
        pointStyle: 'circle'
      },
      line: {
        skipNull: true,
        drawNull: false,
        pointStyle: 'circle'
      }
    },
    scales: {
      x: {
        display: false,
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      },
      y: {
        display: false,
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      }
    }
  }
})
</script>

<template>
  <Card class="border">
    <template #content>
      <div v-if="loading">
        <Skeleton height="1rem" width="15rem" />
        <Skeleton height="5rem" class="mt-2" />
        <Skeleton height="4rem" class="mt-2" />
      </div>
      <div v-else>
        <div class="pb-4">{{ props.title }}</div>
        <div class="w-full flex h-16 justify-center items-center relative">
          <div class="text-3xl text-black font-bold w-2/6">
            {{ props.totals.currentYear.value }}
            <div class="text-xs font-normal">Current year</div>
          </div>
          <div class="w-4/6 relative h-full">
            <Chart type="line" :data="props.chartData" :options="chartOptions" :height="64" />
          </div>
        </div>
        <div class="w-full mt-4 pt-4 border-t flex flex-wrap">
          <div class="flex items-center w-1/2">
            <span class="circle mr-2 bg-green-400"></span> Previous year
          </div>
          <div class="flex justify-end items-center w-1/2 ml-auto text-right">
            {{ props.totals.oneYearPrior.value }}
            <span
              class="pi pi-arrow-down pl-2 pr-1 text-slate-500"
              style="font-size: 0.65rem"
            ></span>
            <span class="text-primary-400"> {{ props.totals.oneYearPrior.difference }}% </span>
          </div>
          <div class="flex items-center">
            <span class="circle mr-2 bg-gray-400"></span> Year before
          </div>
          <div class="flex justify-end items-center w-1/2 ml-auto text-right">
            {{ props.totals.twoYearsPrior.value }}
            <span
              class="pi pi-arrow-down pl-2 pr-1 text-primary-400"
              style="font-size: 0.65rem"
            ></span>
            <span class="text-primary-400"> {{ props.totals.twoYearsPrior.difference }}% </span>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<style scoped>
.circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
</style>
