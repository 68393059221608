import { useAuthStore } from '@/stores/authStore.js'
import { useUserStore } from '@/stores/userStore.js'
import { useAccountStore } from '@/stores/accountStore.js'
import { useApplicationStore } from '@/stores/applicationStore.js'

class ApplicationService {
  async updateAuthData(to) {
    const authStore = useAuthStore()
    const userStore = useUserStore()
    const accountStore = useAccountStore()
    const applicationStore = useApplicationStore()
    applicationStore.initialized = false
    const newAuthenticatedStatus = authStore.authenticated
    console.log('[ApplicationService] Updating auth data', newAuthenticatedStatus)

    const currentRouteName = to.name
    console.log('[ApplicationService] Current routeName', currentRouteName)

    const unguardedPages = ['login', 'noAccess']

    if (newAuthenticatedStatus) {
      if (currentRouteName === 'login') {
        return { name: 'dashboard' }
      }
      console.log('[ApplicationService] User is logged in', newAuthenticatedStatus)
      if (authStore.profile && !userStore.user) {
        await userStore.getUserData()
        await accountStore.getAccountData()
        console.warn('[ApplicationService] setting application as initialized')
        applicationStore.initialized = true

        if (
          !accountStore.account.is_onboarding_complete &&
          !['onboarding', 'onboarding2'].includes(currentRouteName)
        ) {
          return { name: 'onboarding' }
        }
      }
      applicationStore.initialized = true
    } else {
      if (unguardedPages.includes(currentRouteName)) {
        console.log('[ApplicationService] Current route is login, not checking auth')
        console.warn('[ApplicationService] setting application as initialized')
        applicationStore.initialized = true
        return
      }
      console.log('[ApplicationService] Not logged in, going to login')
      return { name: 'login' }
    }
  }
}

export const useApplicationService = () => new ApplicationService()
