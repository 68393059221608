<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  }
})
</script>

<template>
<div class="border rounded-lg bg-white shadow p-4 flex mb-4">
  <div class="mr-4 text-primary font-bold text-xl">
    <font-awesome-icon :icon="faInfoCircle" />
  </div>
  <div>
    <div class="text-primary font-bold text-xl">
        {{ props.notification.title }}
    </div>
    <div class="">
      {{ props.notification.body }}
    </div>
    <div v-if=props.notification.link_text class="pt-2">
      <Button outlined size="small" as="a" :href="props.notification.link ?? 'Link'">
        {{ props.notification.link_text }}
      </Button>
    </div>
  </div>
</div>
</template>

<style scoped>

</style>