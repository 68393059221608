<script setup>
import { useUserStore } from '@/stores/userStore.js'
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowUpRightDots, faTractor } from '@fortawesome/free-solid-svg-icons'
import BusinessReport from '@/pages/sales/sections/BusinessReport.vue'
import { useAccountStore } from '@/stores/accountStore.js'
import NotificationList from '@/components/dashboard/notifications/NotificationList.vue'

const userStore = useUserStore()
const accountStore = useAccountStore()

const isGrower = computed(() => {
  return accountStore.permissions.reports.GROWER_SALES
})

const userInitials = computed(() => {
  return userStore.user.first_name[0] + userStore.user.last_name[0]
})
</script>

<template>
  <div class="w-full justify-center flex py-6 bg-surface-50 border-b">
    <div class="text-2xl w-10/12 font-semibold flex align-middle">
      <Avatar
        class="mr-4"
        :label="userInitials"
        shape="circle"
        style="background-color: #76ab16; color: #eeeeee"
      />
      Hey {{ userStore.user.first_name }}, welcome back
    </div>
  </div>
  <div class="w-full flex flex-wrap justify-center">
    <div class="w-10/12 mt-6 grid grid-cols-3 gap-4">
      <div class="col-span-3 text-xl">What's new</div>
      <div class="col-span-3">
        <notification-list/>
      </div>
      <Card v-if="isGrower" style="overflow: hidden">
        <template #header>
          <div class="w-full card-header card-background-3 flex justify-center items-center">
            <Avatar size="xlarge" shape="circle">
              <font-awesome-icon :icon="faTractor" />
            </Avatar>
          </div>
        </template>
        <template #content>
          <Tag value="New Survey"></Tag>
          <div class="text-2xl mt-4 text-color font-semibold">Growers</div>
          <div class="mt-2">
            Donec nec justo eget felis facilisis fermentum. Aliquam porttitor...
          </div>
          <div class="mt-2">
            <Button
              outlined
              severity="secondary"
              class="text-color"
              as="router-link"
              :to="{ name: 'sales.grower.monthly.report' }"
            >
              Take Part
              <font-awesome-icon :icon="faArrowUpRightDots" />
            </Button>
          </div>
        </template>
      </Card>
    </div>
    <div class="w-10/12 mt-6">
      <BusinessReport :minimal-data="true" />
    </div>
  </div>
</template>

<style scoped>
.card-background-1 {
  background-image: url('/assets/images/leaves1.png');
}
.card-background-2 {
  background-image: url('/assets/images/leaves2.png');
}
.card-background-3 {
  background-image: url('/assets/images/leaves3.png');
}
.card-header {
  min-height: 168px;
}
</style>
