<script setup>
import { useBusinessBenchmarkingStore } from '@/stores/businessBenchmarkingStore.js'
import { useBusinessReportService } from '@/services/businessReportService.js'
import { computed } from 'vue'

const businessBenchmarkingStore = useBusinessBenchmarkingStore()
const businessReportService = useBusinessReportService()
const loading = computed(() => businessBenchmarkingStore.totalSalesAndTransactionsLoading)
const meta = computed(() => businessBenchmarkingStore.totalSalesAndTransactionsData?.meta)
const labels = computed(() => {
  return Object.values(meta.value?.labels.currentYear ?? {})
})
const periods = computed(() => businessBenchmarkingStore.totalSalesAndTransactionsData?.periods)
const data = computed(() => {
  return {
    currentYear: Object.values(periods.value?.currentYear ?? {}).map(
      (period) => period.transactions
    ),
    oneYearPrior: Object.values(periods.value?.oneYearPrior ?? {}).map(
      (period) => period.transactions
    ),
    twoYearsPrior: Object.values(periods.value?.twoYearsPrior ?? {}).map(
      (period) => period.transactions
    )
  }
})
const chartData = computed(() => {
  return {
    datasets: businessReportService.prepareWidgetChartDatasets(
      data.value.currentYear,
      data.value.oneYearPrior,
      data.value.twoYearsPrior,
      false
    ),
    labels: labels.value
  }
})
const chartOptions = computed(() => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    spanGaps: true,
    plugins: {
      legend: {
        display: true
      }
    },
    scales: {
      x: {
        display: true
      },
      y: {
        display: true
      }
    }
  }
})
</script>

<template>
  <Card class="border">
    <template #content>
      <div class="w-full flex">
        <div>
          <div class="font-bold text-lg">Transactions</div>
          <div>Transactions for the last 12 months</div>
        </div>
      </div>
      <div v-if="loading">
        <Skeleton height="20rem" class="mt-6" />
      </div>
      <div v-else>
        <Chart type="line" :options="chartOptions" :data="chartData" :height="300" />
      </div>
    </template>
  </Card>
</template>

<style scoped></style>
