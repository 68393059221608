import axios from 'axios'
import { useAuthStore } from '@/stores/authStore'
import { useUserStore } from '@/stores/userStore.js'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // error.response.
    if (error.response?.status === 401) {
      const authStore = useAuthStore()
      await authStore.logout('/no-access')
    }
    return Promise.reject(error)
  }
)

export const useAxios = () => axiosInstance
