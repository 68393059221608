import { useAuthStore } from '@/stores/authStore.js'
import { useOidc } from '@/composables/oidc.js'

export default {
  install: async () => {
    const authStore = useAuthStore()
    try {
      authStore.loading = true

      const oidc = useOidc()
      if (oidc.isUserLoggedIn) {
        oidc.subscribeToTokensChange((newToken) => {
          console.log('[OIDC] Tokens updated')
          authStore.setToken(newToken)
        })
        const { accessToken, decodedIdToken } = await oidc.getTokens()

        console.log('[Oidc] User is autenticated')
        authStore.setAuthenticated(true)
        authStore.setToken(accessToken)
        authStore.setProfile(decodedIdToken)
      } else {
        console.log('[Oidc] User is not autenticated')
        authStore.setAuthenticated(false)
        authStore.loading = false
      }
    } catch (err) {
      authStore.loading = false
      console.error('[Oidc] Failed to initialize adapter:', err)
    }
  }
}
