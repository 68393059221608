import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useBusinessBenchmarkingStore = defineStore('businessBenchmarkingStore', () => {
  const totalSalesAndTransactionsLoading = ref(false)
  const totalSalesAndTransactionsData = ref({})

  function $reset() {
    totalSalesAndTransactionsLoading.value = false
    totalSalesAndTransactionsData.value = {}
  }

  return {
    totalSalesAndTransactionsLoading,
    totalSalesAndTransactionsData,
    $reset
  }
})
