import { widgetDataFormatType } from '@/enums/widgetDataFormatType'
import { useAxios } from '@/composables/axios.js'
import { useReportStore } from '@/stores/reportStore.js'
import { reportType } from '@/enums/reportType.js'
import { useAccountStore } from '@/stores/accountStore.js'
import { reportFrequency } from '@/enums/reportFrequency.js'
import { weeklyDatePickerExtraOptions } from '@/enums/weeklyDatePickerExtraOptions.js'
import { useDayjs } from '@/composables/dayjs.js'
import { useBusinessBenchmarkingStore } from '@/stores/businessBenchmarkingStore.js'

class BusinessReportService {
  color1 = ''
  color2 = ''
  color3 = ''
  data = {}

  constructor() {
    const documentStyle = getComputedStyle(document.documentElement)
    this.color1 = documentStyle.getPropertyValue('--p-slate-500')
    this.color2 = documentStyle.getPropertyValue('--p-green-400')
    this.color3 = documentStyle.getPropertyValue('--p-green-200')
  }

  async getData(widgetParam, currentAccountId, currentReportType, group, department) {
    const axios = useAxios()

    const { start_date, end_date, frequency, extraDataOption } = this.getDates(currentReportType)

    const { data } = await axios.get(
      `/benchmarking-business/${widgetParam}/${currentAccountId}/${currentReportType}`,
      {
        params: {
          frequency,
          start_date: start_date,
          end_date: end_date,
          extraDataOption: extraDataOption
        }
      }
    )
    return data
  }

  getDates(currentReportType) {
    const dayjs = useDayjs()
    const reportStore = useReportStore()
    let start_date = null
    let end_date = null
    let frequency = reportFrequency.MONTHLY
    let extraDataOption = null
    if (currentReportType === reportType.RETAIL_SALES_WEEKLY) {
      frequency = reportFrequency.WEEKLY
      extraDataOption = reportStore.weekExtraDataOption

      if (reportStore.weekExtraDataOption === weeklyDatePickerExtraOptions.week) {
        start_date = dayjs(reportStore.weekDate)
          .startOf('week')
          .startOf('day')
          .add(5, 'hour')
          .toDate()
        end_date = dayjs(reportStore.weekDate).endOf('week').startOf('day').add(5, 'hour').toDate()
      }
      if (reportStore.weekExtraDataOption === weeklyDatePickerExtraOptions.twelveMonths) {
        const currentIsoWeek = dayjs(reportStore.weekDate).isoWeek()
        start_date = dayjs(reportStore.weekDate)
          .subtract(1, 'year')
          .isoWeek(currentIsoWeek)
          .startOf('week')
          .startOf('day')
          .add(5, 'hour')
          .toDate()
        end_date = dayjs(reportStore.weekDate).endOf('week').startOf('day').add(5, 'hour').toDate()
      }
      if (reportStore.weekExtraDataOption === weeklyDatePickerExtraOptions.calendarYear) {
        start_date = dayjs(reportStore.weekDate)
          .isoWeek(1)
          .startOf('week')
          .startOf('day')
          .add(5, 'hour')
          .toDate()
        end_date = dayjs(reportStore.weekDate).endOf('week').startOf('day').add(5, 'hour').toDate()
      }
    } else {
      if (reportStore.dates[0]) {
        start_date = dayjs(reportStore.dates[0])
        start_date = start_date.startOf('day').add(5, 'hour').toDate()
      }
      if (reportStore.dates[1]) {
        end_date = dayjs(reportStore.dates[1])
        end_date = end_date.startOf('day').add(5, 'hour').toDate()
      }
    }
    return { start_date, end_date, frequency, extraDataOption }
  }

  getColors() {
    return {
      color1: this.color1,
      color2: this.color2,
      color3: this.color3
    }
  }

  async getWidgetsData(reportType) {
    const accountStore = useAccountStore()
    const currentAccountId = accountStore.currentAccount.id
    const businessBenchmarkingStore = useBusinessBenchmarkingStore()
    businessBenchmarkingStore.$reset()

    businessBenchmarkingStore.totalSalesAndTransactionsLoading = true
    this.getData('total-sales', currentAccountId, reportType).then((data) => {
      businessBenchmarkingStore.totalSalesAndTransactionsData = data
      businessBenchmarkingStore.totalSalesAndTransactionsLoading = false
    })
  }

  calculateDifferencePercentage(firstValue, secondValue) {
    if (firstValue && secondValue && (secondValue !== 0 || firstValue !== 0)) {
      return Math.ceil((firstValue * 100) / secondValue - 100)
    }
    return 0
  }

  formatTotal(value, type) {
    //const number = this.shortenNumber(value)
    switch (type) {
      case widgetDataFormatType.PERCENTAGE:
        if (value > 0) {
          return `+${value}%`
        } else {
          return `${value}%`
        }
      case widgetDataFormatType.MONEY:
        return `£${value}`
      default:
        return value
    }
  }

  shortenNumber(value) {
    if (value >= 1e9) {
      return `${(value / 1e9).toFixed(2)}G`
    }
    if (value >= 1e6) {
      return `${(value / 1e6).toFixed(2)}M`
    }
    if (value >= 1e3) {
      return `${(value / 1e3).toFixed(2)}K`
    }
    return value?.toFixed(2) ?? 0
  }

  prepareWidgetChartDatasets(firstDataset, secondDataset, thirdDataset, offset) {
    let firstDatasetValues = []
    let secondDatasetValues = []
    let thirdDatasetValues = []
    const firstDatasetKeys = Object.keys(firstDataset)
    const secondDatasetKeys = Object.keys(secondDataset)
    if (offset) {
      const secondSegmentOffset = []
      const thirdSegmentOffset = []
      for (let i = 0; i < Object.keys(thirdDataset).length; i++) {
        secondSegmentOffset.push(null)
        thirdSegmentOffset.push(null)
      }
      for (let i = 0; i < Object.keys(secondDataset).length; i++) {
        thirdSegmentOffset.push(null)
      }

      firstDatasetValues = [...thirdSegmentOffset, ...Object.values(firstDataset)]
      secondDatasetValues = [...secondSegmentOffset, ...Object.values(secondDataset)]
      thirdDatasetValues = [...Object.values(thirdDataset)]
    } else {
      firstDatasetValues = firstDataset
      secondDatasetValues = secondDataset
      thirdDatasetValues = thirdDataset
    }
    return [
      this.datasetConfig('Current Year', firstDatasetValues, this.color3),
      this.datasetConfig('Previous year', secondDatasetValues, this.color2),
      this.datasetConfig('Year before', thirdDatasetValues, this.color1)
    ]
  }

  datasetConfig(label, data, color) {
    return {
      label: label,
      data: data,
      fill: false,
      borderColor: color,
      pointStyle: true,
      pointRadius: 1,
      tension: 0.4
    }
  }
}

export const useBusinessReportService = () => new BusinessReportService()
