import { defineStore } from 'pinia'
import { useAxios } from '@/composables/axios'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const isLoaded = ref(false)
  const user = ref(null)
  const loading = ref(false)
  const permissions = ref(null)

  const axios = useAxios()
  function $reset() {
    isLoaded.value = false
    user.value = null
    loading.value = false
    permissions.value = null
  }
  async function getUserData() {
    if (!isLoaded.value && !loading.value) {
      console.log('[UserStore] Loading user data')
      loading.value = true
      const response = await axios.get('/users/me')
      user.value = response.data
      await getUserPermissions()
      loading.value = false
      console.log('[UserStore] Successfully loaded user data')
    }
  }
  async function getUserPermissions() {
    loading.value = true
    const response = await axios.get('/permissions/user')
    permissions.value = response.data
    loading.value = false
  }

  return { $reset, isLoaded, user, loading, permissions, getUserData, getUserPermissions }
})
