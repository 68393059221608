import { defineStore } from 'pinia'
import { useAxios } from '@/composables/axios.js'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export const useAccountStore = defineStore(
  'account',
  () => {
    const loading = ref(false)
    const account = ref(null)
    const currentAccount = ref(null)
    const permissions = ref(null)
    const configuration = ref(null)

    const axios = useAxios()

    function $reset() {
      loading.value = false
      account.value = null
      currentAccount.value = null
      permissions.value = null
      configuration.value = null
    }

    async function getAccountData() {
      loading.value = true
      const response = await axios.get(`/users/me/account`)
      account.value = response.data
      await getAccountPermissions()
      loading.value = false
      if (this.currentAccount === null) {
        this.currentAccount = account.value
      }
    }

    async function getAccountPermissions() {
      const accountId = account.value?.id
      loading.value = true
      await axios.get(`/permissions/accounts/${accountId}`).then((response) => {
        permissions.value = response.data
        loading.value = false
      })
    }

    async function changeCurrentAccount(newAccountId) {
      loading.value = true
      const response = await axios.get(`/accounts/${newAccountId}`)
      currentAccount.value = response.data
      loading.value = false
    }

    return {
      $reset,
      loading,
      account,
      currentAccount,
      permissions,
      configuration,
      getAccountData,
      getAccountPermissions,
      changeCurrentAccount
    }
  },
  {
    persist: true
  }
)
