export const permissionType = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  ALL: 'ALL',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  NONE: 'NONE'
}

export const permissionOrder = {
  OWNER: 6,
  ADMIN: 5,
  ALL: 4,
  EDIT: 3,
  VIEW: 2,
  NONE: 1
}

export function permissionTypeIsEqualOrBetterThan(requiredPermission, actualPermission) {
  return permissionOrder[requiredPermission] <= permissionOrder[actualPermission]
}
