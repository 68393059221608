import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { weeklyDatePickerExtraOptions } from '@/enums/weeklyDatePickerExtraOptions.js'
import { useRoute } from 'vue-router'
import { reportType } from '@/enums/reportType.js'
import { useDayjs } from '@/composables/dayjs.js'

export const useReportStore = defineStore('reportStore', () => {
  const dayjs = useDayjs()
  let maxDate = dayjs().subtract(1, 'month')
  let lastYearDate = maxDate.subtract(1, 'year').startOf('month').toDate()
  let weekDateTmp = dayjs().subtract(1, 'week').startOf('week').add(5, 'hour').toDate()

  const route = useRoute()
  const loading = ref(false)
  const dates = ref([lastYearDate, maxDate.endOf('month').startOf('day').add(5, 'hour').toDate()])
  const weekDate = ref(weekDateTmp)
  const weekExtraDataOption = ref(weeklyDatePickerExtraOptions.calendarYear)
  const forceReload = ref(false)

  const timeDifferenceString = computed(() => {
    switch (route.meta.reportTemplateType) {
      case reportType.RETAIL_SALES_WEEKLY:
        switch (weekExtraDataOption.value) {
          case weeklyDatePickerExtraOptions.week:
            return 'week'
          case weeklyDatePickerExtraOptions.calendarYear:
            return 'calendar year'
          case weeklyDatePickerExtraOptions.twelveMonths:
            return 'twelve months'
          default:
            return ''
        }
      case reportType.RETAIL_SALES_MONTHLY:
      case reportType.GROWER_SALES:
        const months = Math.ceil(
          dayjs(dates.value[1])
            .endOf('month')
            .diff(dayjs(dates.value[0]).startOf('month'), 'month', true)
        )
        console.log('[reportStore] months difference ', months)
        if (months > 0) {
          return (
            dayjs(dates.value[0]).format('MMM YYYY') +
            ' - ' +
            dayjs(dates.value[1]).format('MMM YYYY')
          )
        }
        return dayjs(dates.value[0]).format('MMM YYYY')
      default:
        return ''
    }
  })

  function getWeekDateString(date) {
    ;`Wk ${date.isoWeek()}, ${date.format('D MMM, YYYY')} - ${date.format('D MMM, YYYY')}`
  }

  return {
    loading,
    dates,
    weekDate,
    weekExtraDataOption,
    forceReload,
    timeDifferenceString
  }
})
